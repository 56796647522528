<template>
  <module-container
    :title="$t('accounttypes')"
    route-name="accounttypes"
    show-toolbar
  >
    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component
export default class AccounttypesIndex extends Mixins(PaginateProviderMixin) {}
</script>
